//import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import Chart from 'chart.js/auto';
import { ButtonModule } from 'primeng/button';
//import { ChartModule } from 'primeng/chart';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TabMenuModule } from 'primeng/tabmenu';

import { GlobalSharedModule } from '../../shared/shared.module';
import { SchedulePatientGraphComponent } from './components/schedule-patient-graph/schedule-patient-graph.component';
import { SchedulePatientStaffShiftComponent } from './components/schedule-patient-staff-shift/schedule-patient-staff-shift.component';
import { SchedulePatientTreatmentComponent } from './components/schedule-patient-treatment/schedule-patient-treatment.component';
import { ScheduleWisePatientComponent } from './schedule-wise-patient.component';

@NgModule({
  imports: [
    //Chart,
    ButtonModule,
    //ChartModule,
    CommonModule,
    DragDropModule,
    DropdownModule,
    InputMaskModule,
    InputTextModule,
    MenuModule,
    MenubarModule,
    FormsModule,
    GlobalSharedModule,
    RouterModule,
    SelectButtonModule,
    TabMenuModule,
  ],
  declarations: [
    ScheduleWisePatientComponent,
    SchedulePatientTreatmentComponent,
    SchedulePatientStaffShiftComponent,
    SchedulePatientGraphComponent,
  ],
})
export class ScheduleWisePatientModule {}
